<script setup>
import ApplicationLogo from '@/Components/ApplicationLogo.vue'
import IconAgenda from '@/Icons/agenda.vue'
import IconDown from '@/Icons/arrow-down.vue'
import IconCandidates from '@/Icons/candidates.vue'
import IconDashboard from '@/Icons/dashboard.vue'
import IconJobs from '@/Icons/jobs.vue'
import IconNewsFeed from '@/Icons/newsFeed.vue'
import IconProperty from '@/Icons/property.vue'
import { Head, Link } from '@inertiajs/vue3'
import { onMounted, ref } from 'vue'


const activeUrl = ref(window.location.pathname);
const isJobListUrl = activeUrl.value.startsWith("/job-list/");
const isPropertyUrl = activeUrl.value.startsWith('/feed');
const jobLastPart = isJobListUrl ? activeUrl.value.split("/").pop() : '';

const showJobs = ref(isJobListUrl);
const showProperty = ref(isPropertyUrl);
const toggleJobs = () => {
    showJobs.value = !showJobs.value;
}
const toggleProperty = () => {
    showProperty.value = !showProperty.value;
}

const isActive = (url) => {
    return activeUrl.value === url;
}

const css = {
    bull_active: 'w-2 h-2 bg-main rounded-full',
    bull: 'w-1 h-1 bg-gray-500 rounded-full',
    label_active: 'self-stretch text-main text-sm font-semibold leading-snug',
    label: 'self-stretch text-500 text-sm font-medium leading-snug  ',
    bg_active: 'bg-main bg-opacity-10 active',
    bg_inactive: 'bg-white bg-opacity-0 transition-all hover:bg-grey-8'
}
const updateActiveUrl = (url) => {
    activeUrl.value = url;
}

const countUnreadMessages = ref(0);

const getUnreadMessages = () => {
    axios.get(route('chat.unread-messages-jobs'))
        .then(response => {
            if (response.data.unreadMessagesCount > 0) {
                countUnreadMessages.value = response.data.unreadMessagesCount;
            }
        })
        .catch(error => {
            console.log(error);
        });
}
onMounted(() => {
    getUnreadMessages();
});

</script>

<template>

    <div
        class="w-[280px] h-screen px-4 pb-10 bg-white border-r border-gray-400 border-opacity-20 flex-col justify-start items-center inline-flex absolute z-[60] md:transition-none md:duration-0">
        <div class="self-stretch h-[69px] px-4 pt-6 pb-2  items-center flex">
            <div class="w-[168px] pb-[3px] justify-center items-center inline-flex">
                <img class="grow shrink basis-0 self-stretch max-w-[168px] max-h-[50px] object-contain" :src="$page.props.auth.organization?.avatar?.media1?.url || `/assets/img/company.png`
                    " :alt="$page.props.auth.organization?.name" />
                <!-- <img class="self-stretch grow shrink basis-0" src="https://via.placeholder.com/168x34" /> -->
            </div>
        </div>
        <div class="flex flex-col items-start self-stretch justify-start gap-1 p-2 grow shrink basis-0">
            <div class="inline-flex items-center self-stretch justify-start pt-4 pb-2 pl-3 bg-white rounded-lg">
                <div class="text-gray-400 text-[11px] font-bold uppercase leading-[18px]">{{ $t('Management') }}</div>
            </div>

            <Link href="/dashboard" @click="updateActiveUrl('/dashboard')"
                :class="isActive('/dashboard') ? css.bg_active : css.bg_inactive"
                class="inline-flex items-center self-stretch justify-start p-2 pl-3 pr-2 bg-white bg-opacity-0 rounded-lg min-h-11 group"
                @mouseover="hovering = true" @mouseout="hovering = false"
                :style="hovering ? { backgroundColor: 'rgba(0, 0, 255, 0.1)' } : {}">
            <div class="flex items-center justify-center pr-4">
                <div class="relative w-6 h-6">
                    <IconDashboard />
                </div>
            </div>
            <div class="inline-flex flex-col items-center justify-center pr-4 grow shrink basis-0">
                <div :class="isActive('/dashboard') ? css.label_active : css.label">{{ $t('Dashboard') }}</div>
            </div>
            </Link>





            <!-- Property -->
            <Link href="/property" @click.prevent="updateActiveUrl('/property')"
                :class="isActive('/property') ? css.bg_active : css.bg_inactive"
                class="inline-flex items-center self-stretch justify-start p-2 pl-3 pr-2 rounded-lg min-h-11 group">
            <div class="flex items-center justify-center pr-4">
                <div class="relative w-6 h-6">
                    <IconProperty />
                </div>
            </div>
            <div class="inline-flex flex-col items-center justify-center pr-4 grow shrink basis-0">
                <div :class="isActive('/property') ? css.label_active : css.label">{{ $t('Property') }}</div>
            </div>
            <div class="flex items-center justify-center   group-[&.active]:rotate-180" @click.prevent="toggleProperty">
                <div class="relative w-4 h-4"  >
                    <IconDown />
                </div>
            </div>
            </Link>

            <!-- <Link href="/feed" @click="updateActiveUrl('/feed')" v-if="showProperty"
                :class="isActive('/feed') ? css.bg_active : css.bg_inactive"
                class="inline-flex items-center self-stretch justify-start p-2 pl-3 pr-2 bg-white bg-opacity-0 rounded-lg min-h-11 group"
                @mouseover="hovering = true" @mouseout="hovering = false"
                :style="hovering ? { backgroundColor: 'rgba(0, 0, 255, 0.1)' } : {}">

            <div class="flex items-center justify-center pr-4">
                <div class="flex items-center justify-center w-6 h-6">
                    <div :class="isActive('/feed') ? css.bull_active : css.bull"></div>
                </div>
            </div>
            <div class="inline-flex flex-col items-center justify-center pr-4 grow shrink basis-0">
                <div :class="isActive('/feed') ? css.label_active : css.label">{{ $t('News feed') }}</div>
            </div>

            </Link> -->
            <!-- END Property -->


            <Link href="/job-list/all" @click="updateActiveUrl('/job-list/all')"
                class="inline-flex items-center self-stretch justify-start p-2 pl-3 pr-2 rounded-lg min-h-11 group"
                :class="isActive('/job-list/all') ? css.bg_active : css.bg_inactive">
            <div class="flex items-center justify-center pr-4">
                <div class="relative w-6 h-6">
                    <!-- Jobs -->
                    <IconJobs />
                </div>
            </div>
            <div class="inline-flex flex-col items-center justify-center pr-4 grow shrink basis-0">
                <div :class="isActive('/job-list/all') ? css.label_active : css.label">{{ $t('Jobs') }}</div>
            </div>
            <div v-if="countUnreadMessages > 0"
                class="w-fit px-1.5 py-1 bg-system-dark bg-opacity-20 rounded-md justify-start items-center gap-1.5 flex">
                <div class="text-center text-system-dark text-xs font-bold font-[Montserrat] leading-tight">{{
                    countUnreadMessages }}</div>
            </div>
            <div class="flex items-center justify-center  group-[&.active]:rotate-180" @click="toggleJobs">
                <div class="relative w-4 h-4"  >
                    <IconDown />
                </div>
            </div>
            </Link>

            <!-- Start Jobs sub -->
            <Link href="/job-list/active" @click="updateActiveUrl('/job-list/active')" v-if="showJobs"
                class="inline-flex items-center self-stretch justify-start pl-3 pr-2 transition-all bg-white bg-opacity-0 rounded-lg hover:bg-grey-8 group">
            <div class="flex items-center justify-center pr-4">
                <div class="flex items-center justify-center w-6 h-6">
                    <div :class="isActive('/job-list/active') ? css.bull_active : css.bull"></div>
                </div>
            </div>
            <div class="inline-flex flex-col items-center justify-center pr-4 grow shrink basis-0">
                <div :class="isActive('/job-list/active') ? css.label_active : css.label">{{ $t('Active') }}</div>
            </div>
            </Link>

            <Link href="/job-list/draft" @click="updateActiveUrl('/job-list/draft')" v-if="showJobs"
                class="inline-flex items-center self-stretch justify-start pl-3 pr-2 transition-all bg-white bg-opacity-0 rounded-lg hover:bg-grey-8 group">

            <div class="flex items-center justify-center pr-4">
                <div class="flex items-center justify-center w-6 h-6">
                    <div :class="isActive('/job-list/draft') ? css.bull_active : css.bull"></div>
                </div>
            </div>
            <div class="inline-flex flex-col items-center justify-center pr-4 grow shrink basis-0">
                <div :class="isActive('/job-list/draft') ? css.label_active : css.label">{{ $t('Drafts') }}</div>
            </div>
            </Link>

            <!-- <Link href="/job-list/deleted" @click="updateActiveUrl('/job-list/deleted')" v-if="showJobs"
                class="inline-flex items-center self-stretch justify-start pl-3 pr-2 transition-all bg-white bg-opacity-0 rounded-lg hover:bg-grey-8 group">
            <div class="flex items-center justify-center pr-4">

                <div class="flex items-center justify-center w-6 h-6">
                    <div :class="isActive('/job-list/deleted') ? css.bull_active : css.bull"></div>
                </div>
            </div>
            <div class="inline-flex flex-col items-center justify-center pr-4 grow shrink basis-0">
                <div :class="isActive('/job-list/deleted') ? css.label_active : css.label">{{ $t('Deleted') }}</div>
            </div>
            </Link> -->

            <Link href="/job-list/archived" @click="updateActiveUrl('/job-list/archived')" v-if="showJobs"
                class="inline-flex items-center self-stretch justify-start pl-3 pr-2 transition-all bg-white bg-opacity-0 rounded-lg hover:bg-grey-8 group">
            <div class="flex items-center justify-center pr-4">
                <div class="flex items-center justify-center w-6 h-6">
                    <div :class="isActive('/job-list/archived') ? css.bull_active : css.bull"></div>
                </div>
            </div>
            <div class="inline-flex flex-col items-center justify-center pr-4 grow shrink basis-0">
                <div :class="isActive('/job-list/archived') ? css.label_active : css.label">{{ $t('Archived') }}</div>
            </div>
            </Link>
            <Link href="/job-list/duplicate" @click="updateActiveUrl('/job-list/duplicate')" v-if="showJobs"
                class="inline-flex items-center self-stretch justify-start pl-3 pr-2 transition-all bg-white bg-opacity-0 rounded-lg hover:bg-grey-8 group">
            <div class="flex items-center justify-center pr-4">
                <div class="flex items-center justify-center w-6 h-6">
                    <div :class="isActive('/job-list/duplicate') ? css.bull_active : css.bull"></div>
                </div>
            </div>
            <div class="inline-flex flex-col items-center justify-center pr-4 grow shrink basis-0">
                <div :class="isActive('/job-list/duplicate') ? css.label_active : css.label">{{ $t('Duplicate Jobs') }}</div>
            </div>
            </Link>

            <!-- End Jobs -->



            <!-- <Link href="/candidates" @click="updateActiveUrl('/candidates')"
                :class="isActive('/candidates') ? css.bg_active : css.bg_inactive"
                class="inline-flex items-center self-stretch justify-start p-2 pl-3 pr-2 transition-none hover:bg-grey-8 group">
            <div class="flex items-center justify-center pr-4">
                <div class="relative w-6 h-6">
                    <IconCandidates />
                </div>
            </div>
            <div class="inline-flex flex-col items-center justify-center pr-4 grow shrink basis-0">
                <div :class="isActive('/candidates') ? css.label_active : css.label">{{ $t('Candidates') }}</div>
            </div>
            </Link> -->

            <!-- <Link :href="route('agendas.index', 'hr')" @click.prevent="updateActiveUrl('/agenda/hr')"
                :class="isActive('/agenda/hr') ? css.bg_active : css.bg_inactive"
                class="inline-flex items-center self-stretch justify-start p-2 pl-3 pr-2 transition-none rounded-lg group">
            <div class="flex items-center justify-center pr-4">
                <div class="relative w-6 h-6">
                    <IconAgenda />
                </div>
            </div>
            <div class="inline-flex flex-col items-center justify-center pr-4 grow shrink basis-0">
                <div :class="isActive('/agenda/hr') ? css.label_active : css.label">{{ $t('Agenda') }}</div>
            </div>
            </Link> -->

            <Link
                :href="route('feed')"
                @click.prevent="updateActiveUrl('/feed')"
					 :class="
                    isActive('/feed')
                        ? css.bg_active
                        : css.bg_inactive
                "
                class="inline-flex items-center self-stretch justify-start p-2 pl-3 pr-2 transition-none rounded-lg group"
            >
                <div class="flex items-center justify-center pr-4">
                    <div class="relative w-6 h-6">
                        <IconNewsFeed />
                    </div>
                </div>
                <div class="inline-flex flex-col items-center justify-center pr-4 grow shrink basis-0">
                    <div :class="isActive('/feed') ? css.label_active : css.label">
                        {{ $t("News feed") }}
                    </div>
                </div>
            </Link>

            <Link :href="route('events.index', 'super-admin')" @click.prevent="updateActiveUrl('/events/super-admin')"
                :class="isActive('/events/super-admin') ? css.bg_active : css.bg_inactive"
                class="inline-flex items-center self-stretch justify-start p-2 pl-3 pr-2 transition-none rounded-lg group">
                <div class="flex items-center justify-center pr-4">
                    <div class="relative w-6 h-6">
                        <IconAgenda />
                    </div>
                </div>
                <div class="inline-flex flex-col items-center justify-center pr-4 grow shrink basis-0">
                    <div :class="isActive('/events/super-admin') ? css.label_active : css.label">{{ $t('Public Events') }}</div>
                </div>
            </Link>


        </div>

        <div class="text-left">
            <ApplicationLogo class="py-2 pl-0 m-0 ml-[-25px]" />
        </div>
    </div>
</template>
