<template>
   <div class="w-full h-8 fixed text-white bg-[#0516B2] text-center top-0 z-50 pt-1">
      <span class="hidden sm:block">{{ $t('Hospitaliti is currently in Beta - your feedback is important to us') }}</span>
      <span class="sm:hidden">{{ $t('Hospitaliti is in Beta') }}</span>
   </div>
   <div class="relative top-8">
       <GenericButton v-if="isSmallerScreen && isMenuOpen" @click="toggleMenu" class="flex w-7 h-7 !p-0  cursor-pointer items-center justify-center
           fixed left-[240px] top-2 bg-transparent" style="z-index: 999;">
           <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
               <path d="M1 13L12.94 1M13 13L1.06 1" stroke="black" stroke-width="1.2" stroke-linecap="round"
                   stroke-linejoin="round" />
           </svg>
       </GenericButton>
       <div class="flex flex-row min-h-screen bg-white">
           <div
               class="w-full h-screen fixed  top-0 md:top-8 transition-all duration-200 bg-[rgba(0,0,0,0.35)] md:bg-white md:w-fit md:duration-0"
               :class="{
                'opacity-100 visible': isMenuOpen,
               'opacity-0 invisible': !isMenuOpen,
               'z-[80]': isSmallerScreen
               }"
           >
               <div class="fixed bg-[rgba(0,0,0,0.6)] w-full h-full z-[60] top-0 right-0 md:hidden" @click.prevent="toggleMenu" v-show="isSmallerScreen && isMenuOpen">
               </div>
               <component
                   @close-menu="handleCloseMenu"
                   :is="leftComponent"
                   class="absolute top-0 h-full w-[280px] overflow-y-auto transition-all duration-300 md:duration-0"
                   :class="{ 'left-0': isMenuOpen, 'left-[-280px]': !isMenuOpen }"
               />
           </div>

           <div class="w-full md:ml-[280px] flex flex-col md:pt-[100px] overflow-hidden px-4 sm:px-6 lg:px-8 pb-6 sm:pb-8 lg:pb-10">
               <nav class="w-full bg-white">
                   <!-- Primary Navigation Menu -->
                   <div class=" w-full fixed left-0 md:top-8 z-[60] md:w-[calc(100%-280px)] md:left-[280px] bg-white px-0 px-6 lg:px-8">
                       <div class="flex flex-col justify-end gap-4 pt-3 pb-2 md:flex-row md:py-4 lg:py-5">
                          <!-- Navigation Links -->
                          <div class="flex-auto space-x-8 md:z-50 sm:-my-px sm:flex">
                               <div class="relative flex items-center flex-auto ">

                                <!-- Search -->
                                <!--  
                                   <div class="absolute -translate-y-1/2 top-1/2 left-4">
                                       <Icon type="search" />
                                   </div>
                                   <input v-if="auth.user" type="search" name="search" id="search" autocomplete="off" :placeholder="$t('Search for people')" @keyup="searchUser" v-model="searchItem"
                                       class="w-full text-sm py-2.5 pl-12 pr-4 rounded-full overflow-hidden border border-[#919EAB33] outline-none" />
                                   <input v-else type="search" disabled name="search" id="search" autocomplete="off" :placeholder="$t('Sign in to search')"
                                       class="w-full text-sm py-2.5 pl-12 pr-4 rounded-full overflow-hidden border border-[#919EAB33] outline-none" />
                                -->       
                                </div> 
                          </div>

                           <div class="flex flex-wrap items-center justify-between gap-3">
                               <!-- Hamburger -->
                               <div class="flex items-center justify-end flex-auto shrink-0">
                                   <div class="flex items-center flex-auto " v-if="isSmallerScreen && !isMenuOpen">
                                       <GenericButton @click="toggleMenu"
                                           class="inline-flex flex-col items-center justify-center !border-none !gap-0 p-2 text-gray-400 transition duration-150 ease-in-out bg-transparent rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500">
                                           <div class="w-8 h-0.5 bg-black mb-1.5"></div>
                                           <div class="w-8 h-0.5 bg-black mb-1.5"></div>
                                           <div class="w-8 h-0.5 bg-black"></div>
                                       </GenericButton>
                                   </div>
                                   <TopIconMenu />
                                   <!-- Settings Dropdown -->
                                   <div class="relative">
                                       <Dropdown align="right" width="48">
                                           <template #trigger>
                                               <div class="">
                                                   <GenericButton type="button" theme="inherit-text" class="flex !border-none">
                                                       <Avatar v-if="auth.user" size="9" class="cursor-pointer border-[#919EAB14]" />
                                                   </GenericButton>
                                               </div>
                                           </template>

                                           <template #content>
                                               <DropdownLink :href="route('candidate.get')" v-if="
                                                   leftComponent == 'LeftCandidate'
                                               ">
                                                   {{ $t("Profile") }}
                                               </DropdownLink>
                                               <DropdownLink :href="route('profile.edit')">{{
                                                   $t("Account")
                                                   }}</DropdownLink>
                                               <!-- <DropdownLink :href="route('logout')" method="post" as="button"> -->
                                               <DropdownLink @click="logout" as="button">
                                                   {{ $t("Log Out") }}
                                               </DropdownLink>
                                           </template>
                                       </Dropdown>
                                   </div>
                               </div>
                           </div>

                           <!-- search result -->
                           <div v-if="userList.length > 0" class="absolute z-[80] md:w-[calc(100%-250px)] left-0 md:top-[80px] w-full top-[60px]">
                              <div class="flex flex-col w-full p-3 bg-white border rounded-md shadow z-100">
                                 <template v-for="user in userList" :key="user.id">
                                    <a :href="user.route_url" class="flex items-center justify-between px-4 py-4 text-gray-700 rounded-md cursor-pointer hover:bg-gray-200 active:bg-blue-100">
                                       <div class="flex items-center space-x-4">
                                          <Icon type="search" />
                                          <span class="font-bold lg:text-xl text-md overflow-hidden text-ellipsis whitespace-nowrap max-w-[165px] lg:max-w-full">{{ user.type ? user.name : user.fullname }}</span>
                                          <span class="hidden ml-4 text-gray-400 font-italic lg:block lg:text-sm"> {{ getUserTitle(user) }}</span>
                                       </div>
                                       <Avatar :user="user"/>
                                    </a>
                                 </template>
                                 <a v-if="userList.length > 9" :href="route('list.users', searchItem)" class="flex items-center justify-between px-4 py-4 text-gray-700 rounded-md cursor-pointer hover:bg-gray-200 active:bg-blue-100">
                                    <p class="ml-4 font-bold lg:text-xl text-md">{{ $t('See all...') }}</p>
                                 </a>
                              </div>
                           </div>

                       </div>
                   </div>
               </nav>
               <div class="relative flex flex-col flex-1 top-[90px] md:top-0">
                   <div class="max-w-[67.5em] flex flex-col flex-auto w-full mx-auto justify-start">
                       <slot />
                   </div>
               </div>
           </div>
       </div>
   </div>
    <ovn-toast ref="toast"></ovn-toast>
</template>

<script>
import Avatar from "@/Components/Avatar.vue";
import Dropdown from "@/Components/Dropdown.vue";
import DropdownLink from "@/Components/DropdownLink.vue";
import GenericButton from "@/Components/GenericButton.vue";
import NavLink from "@/Components/NavLink.vue";
import ResponsiveNavLink from "@/Components/ResponsiveNavLink.vue";
import OvnToast from "@/Components/Toast.vue";
import Icon from '@/Icons/perks-icons.vue';
import LeftCandidate from "@/Layouts/Left/LeftCandidate.vue";
import LeftHR from "@/Layouts/Left/LeftHR.vue";
import TopIconMenu from "@/Layouts/Partials/TopIconMenu.vue";
import { useFcmStore } from "@/Stores/useFcmStore.js";
import { hasPermissions } from "@/Utils/permissions.js";
import axios from "axios";
import { debounce, isEmpty } from "lodash";

export default {
   name: "AuthenticatedLayout",
   components: {
       OvnToast,
       Avatar,
       LeftHR,
       LeftCandidate,
       Dropdown,
       DropdownLink,
       Icon,
       NavLink,
       ResponsiveNavLink,
       TopIconMenu,
       GenericButton
   },
   data() {
       return {
           showingNavigationDropdown: false,
           auth: this.$page.props.auth,
           isMenuOpen: false,
           isSmallerScreen: false,
           searchItem: "",
           showList: false,
           userList: [],
       };
   },
   props: {
       //company: Object,
       errors: Object,
       //auth: Object,
       left: String,
   },
   computed: {
       showLeft() {
           return (
               !hasPermissions(this.USER_PERMISSIONS.GUEST) ||
               this.left === "hr"
           );
       },
       leftComponent() {
           if (
               hasPermissions(this.USER_PERMISSIONS.HR_PORTAL) ||
               this.left === "hr"
           ) {
               return "LeftHR";
           } else {
               return "LeftCandidate";
           }
       },
       fcmStore() {
           return useFcmStore();
       },
       //locations() {
       //},
       //monthsList() {
       //},
   },
   watch: {
     searchItem(newVal) {
        if(!newVal) {
           this.showList = false;
        } else {
           this.showList = true;
        }
        this.searchUser(newVal);
     },
 },
   methods: {
     getUserTitle(user) {
         const candidatText = isEmpty(user.candidate_texts) ? null : user.candidate_texts;
         if(candidatText == null) return null;
         const candidateTexts = user.candidate_texts.filter((item) => {
            if(item.end_date == null) return true;
         });
         console.log(candidateTexts);
         return candidateTexts[0].title;
     },
       toggleMenu() {
           this.checkScreenSize();
           if (this.isSmallerScreen) {
               this.isMenuOpen = !this.isMenuOpen; // Toggle the menu on smaller screens
           }
       },
       checkScreenSize() {
           this.isSmallerScreen = window.innerWidth < 768; // Update isSmallerScreen based on window width
       },
       handleCloseMenu() {
           this.checkScreenSize();
           if (this.isSmallerScreen) {
               this.isMenuOpen = false
           }

       },
       updateUserStatus() {
            if(this.auth.user) {
               axios.put(route('chat.user.update.status'));
            }
       },
     searchUser: debounce(function(query) {
        this.search(query.target.value);
     }, 600),

     async search(query) {
        // Perform search with query
        await axios.get(route('search.users', { search :query}))
           .then(response => {
               this.userList = response.data;
           })
           .catch(error => {
               console.log(error);
           });
     },
       onPushMessageReceived(payload) {
           console.log('onPushMessageReceived', payload);

           let body = `<div><strong>${payload.notification.title}</strong>`;
           body += `<p>${payload.notification.body.replace("\n",'<br>')}</p></div>`;
           let message = '';
           if (payload.fcmOptions && payload.fcmOptions.link) {
               message = `<a href="${payload.fcmOptions.link}" class="w-full">`;
               message += body;
               message += `</a>`;
           } else {
               message = body;
           }
           this.$refs.toast.show(message, 'info', 7000);
       },
       logout() {
           this.$inertia.post(route("logout"));
           this.auth.user = null
       }
   },
   mounted() {
       // console.log(this.$page)
       this.checkScreenSize();

       // Open the menu by default on larger screens
       this.isMenuOpen = !this.isSmallerScreen;
       window.addEventListener('resize', this.checkScreenSize);
       this.updateUserStatus();
       this.fcmStore.firebaseInit((payload) => {
           this.onPushMessageReceived(payload);
       });
   },
   beforeUnmount() {
       // Remove resize event listener to prevent memory leaks
       window.removeEventListener('resize', this.checkScreenSize);
   },
   updated() {
       this.updateUserStatus();
   },
};
</script>

<style scoped>
#Sidebar {
   position: fixed;
   z-index: 5;
}
</style>
