<template>
    <div class="flex-auto w-full max-w-[320px] md:max-w-[420px] mx-auto text-sm bg-white shadow">
      <header class="flex w-full p-5 bg-white">
        <h2 class="text-lg font-bold">{{ $t('Notifications') }}</h2>
        <div class="flex items-start justify-end w-full">
          <GenericButton
            type="button"
            theme="primary-text"
            @click="markAllAsRead">
            <svg width="18" height="9" viewBox="0 0 18 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M12.6936 0.277581C13.0205 0.60156 13.0229 1.12919 12.6989 1.45608L10.0089 4.17025C9.6849 4.49714 9.15727 4.4995 8.83038 4.17552C8.50349 3.85154 8.50113 3.32391 8.82511 2.99702L11.5151 0.282852C11.8391 -0.0440374 12.3667 -0.046397 12.6936 0.277581ZM16.862 0.280176C17.1875 0.605594 17.1875 1.13323 16.8621 1.45869L9.79212 8.52952C9.63584 8.68582 9.42387 8.77363 9.20285 8.77363C8.98183 8.77364 8.76986 8.68584 8.61357 8.52956L5.07774 4.99372C4.7523 4.66828 4.7523 4.14065 5.07774 3.81521C5.40317 3.48977 5.93081 3.48977 6.25625 3.81521L9.20279 6.76175L15.6835 0.280245C16.009 -0.0452108 16.5366 -0.0452419 16.862 0.280176ZM0.91107 3.81521C1.23651 3.48977 1.76414 3.48977 2.08958 3.81521L5.62541 7.35104C5.95085 7.67648 5.95085 8.20412 5.62541 8.52956C5.29998 8.85499 4.77234 8.85499 4.4469 8.52956L0.91107 4.99372C0.585633 4.66828 0.585633 4.14065 0.91107 3.81521Z" fill="#0516B2"/>
            </svg>
          </GenericButton>
        </div>
      </header>

      <!-- Tabs for filtering notifications -->
       <hr>
      <div class="flex gap-10 px-5 text-sm border-b">
        <button
          class="inline-block py-3 font-bold border-b-2 border-transparent rounded-t-lg text-disabled dark:text-blue-500 dark:border-blue-500"
          :class="{ ' !text-black  !border-black ': activeTab === 'all', 'text-black': activeTab !== 'all' }"
          @click="activeTab = 'all'">
          {{ $t('All') }} <span  v-if="notifications.length > 0" class="px-1.5 py-0.5 text-xs text-white bg-black rounded-md ml-2">{{ unreadNotifications.length }}</span>
        </button>
      
        <button
          class="inline-block py-3 font-bold border-b-2 border-transparent rounded-t-lg text-disabled dark:text-blue-500 dark:border-blue-500"
          :class="{ '!text-black  !border-black ': activeTab === 'unread', 'text-black': activeTab !== 'unread' }"
          @click="activeTab = 'unread'">
          {{ $t('Unread') }} <span v-if="unreadNotifications.length > 0" class="text-[#006C9C] bg-[#b0f3ff] rounded-md px-1.5 py-0.5 ml-2 ">{{ unreadNotifications.length }}</span>
        </button>
      </div>

      <!-- Display notifications based on active tab -->
      <div v-if="activeTab === 'all'">
        <div v-for="notification in notifications" :key="notification.id" class="flex items-start gap-2 p-5 border-b border-dashed">
          <Avatar v-if="notification.type === 'application'" :user="notification.notifiable?.user" size="10"/>
          <Avatar v-if="notification.type === 'job-application'" :user="{ ...notification.notifiable, avatar: notification.notifiable.logo }" size="10"/>
          <Avatar v-if="notification.type === 'friend_request'" :user="notification?.data?.sender" size="10"/>
          <Avatar v-if="notification.type === 'communication_chat_message'" :user="notification?.data?.sender" size="10"/>
          <div class="flex-1">
            <p v-if="notification.type === 'application'">
              {{ $t('You have received a new application for the Job') }}:
              <a :href="`/communications/${notification?.data.job_id}/${notification.notifiable?.id}`" class="text-blue-500 underline">
                {{ notification.data.job_name }}
              </a>
            </p>
            <p v-if="notification.type === 'job-application'">
              {{ $t('Congratulation! You are now in the {s} stage with your application to {t}').replace('{s}', notification.data.stage).replace('{t}', notification.data.job_title) }}
            </p>
            <p v-if="notification.type === 'friend_request'">
              <b>{{notification?.data?.sender?.fullname}} </b> {{ notification?.data?.message}}
            </p>
            <span class="text-xs text-disabled" v-if="notification.type !== 'communication_chat_message'">{{ formattedDate(notification?.created_at) }} - Communication</span>
            <div class="flex items-center justify-start gap-3 mt-2" v-if="notification?.data?.status === 'pending'">
              <GenericButton
				  class="!py-3 !px-1"
                type="button"
                theme="inherit-contained"
                :text="$t('Accept')"
                @click="accepted(notification)" />
              <GenericButton
				  class="!py-3 !px-1"
                type="button"
                theme="inherit-outlined"
                :text="$t('Decline')"
                @click="decline(notification)" />
            </div>
            <div v-if="notification.type === 'communication_chat_message'" class="flex flex-col w-full  gap-0.5">
               
					 <div class="flex items-center w-full space-between">
						<p class="block font-sans antialiased font-light leading-relaxed text-inherit">
                    <span class="font-semibold" >{{ notification?.data?.sender?.fullname }}</span> <span v-if="notification?.data?.message" >sends: {{ notification?.data?.message }}</span>
                </p>
						<div v-if="!notification.read_at" class="w-2.5 h-2.5 bg-[#00B8D9] rounded-full ml-auto"></div>
					 </div>
                <span class="text-xs text-disabled" >{{ formattedDate(notification?.created_at) }}</span>
                <div class="flex w-full flex-col  gap-0.5" v-if="notification?.data?.attachmentId">
                    <p class="block font-sans antialiased font-light leading-relaxed text-inherit">
                        {{ notification.data.sender.fullname }} sent you a file
                        <span class="font-semibold" >{{ notification.data.attachment_name }}</span>
                    </p>
                    <div class="flex items-center justify-between w-full p-2 mt-3 bg-white border rounded-md cursor-pointer">
                        <div 
                           @click="previewFile(
                              route('document.show-in-chat', [notification?.data.groupId, notification?.data.attachmentId, notification?.document.type]),
                              notification?.document.type,
                              notification?.document.encid
                           )"
                        >
                            <Icon type="file" height="50" width="50" />
                        </div>
                        <div 
                           @click="previewFile(
                              route('document.show-in-chat', [notification?.data.groupId, notification?.data.attachmentId, notification?.document.type]),
                              notification?.document.type,
                              notification?.document.encid
                           )"
                        >
                            <h4>{{ notification?.data?.attachment_name }}</h4>
                        </div>
                        <div>
                        <!-- <GenericButton
                            :href="route('attachment.download', notification?.data?.attachmentId)"
                            type="button"
                            :isLink="true"
                            theme="inherit-outlined"
                            class="cursor-pointer hover:text-[#212B36] bg-white hover:border hover:border-[#919EAB52]"
                            :text="$t('Download')" /> -->
                           <a v-if="notification?.attachment?.id"
                              :href="route('attachment.download', notification?.attachment?.id)" target="_blank"
                              class="cursor-pointer hover:text-[#212B36] bg-white hover:border hover:border-[#919EAB52] items-center gap-2 rounded-lg px-3 py-1 font-bold leading-tight text-sm border border-secondary-10 text-secondary-8 bg-secondary-10"
                           >
                           {{ $t('Download') }}</a>
                        </div>
                    </div>
                </div>
                <div class="mt-3" v-if="notification?.data?.message || notification?.data?.attachmentId">
                    <GenericButton
                    @click="goToChat(notification?.group_type, notification?.job?.slug, notification?.data?.application_id, notification?.data?.message_id, notification?.data?.groupId)"
                    type="button"
                    theme="inherit-contained"
                    class="cursor-pointer hover:text-[#212B36] hover:bg-white hover:border hover:border-[#919EAB52] transition-all !py-1 !px-3"
                    :text="$t('Reply')" />
                </div>

            </div>
          </div>
 
        </div>
      </div>

      <!-- Unread notifications -->
      <div v-if="activeTab === 'unread'">
        <div v-for="notification in unreadNotifications" :key="notification.id" class="flex items-start gap-2 p-5 border-b border-dashed">
          <Avatar v-if="notification.type === 'application'" :user="notification.notifiable?.user" size="10"/>
          <Avatar v-if="notification.type === 'job-application'" :user="{ ...notification.notifiable, avatar: notification.notifiable.logo }" size="10"/>
          <Avatar v-if="notification.type === 'friend_request'" :user="notification?.data?.sender" size="10"/>
          <Avatar v-if="notification.type === 'communication_chat_message'" :user="notification?.data?.sender" size="10"/>
          <div class="flex-1">
            <p v-if="notification.type === 'application'">
              {{ $t('You have received a new application for the Job') }}:
              <a :href="`/communications/${notification?.data.job_id}/${notification.notifiable?.id}`" class="text-blue-500 underline">
                {{ notification.data.job_name }}
              </a>
            </p>
            <p v-if="notification.type === 'job-application'">
              {{ $t('Congratulation! You are now in the {s} stage with your application to {t}').replace('{s}', notification.data.stage).replace('{t}', notification.data.job_title) }}
            </p>
            <p v-if="notification.type === 'friend_request'">
              <b>{{notification?.data?.sender?.fullname}} </b> {{ notification?.data?.message}}
            </p>
            <span class="text-xs text-disabled" v-if="notification.type !== 'communication_chat_message'">{{ formattedDate(notification?.created_at) }} - Communication</span>
            <div class="flex items-center justify-start gap-3 mt-2" v-if="notification?.data?.status === 'pending'">
              <GenericButton
				  class="!py-3 !px-1"
                type="button"
                theme="inherit-contained"
                :text="$t('Accept')"
                @click="accepted(notification)" />
              <GenericButton
				  class="!py-3 !px-1"
                type="button"
                theme="inherit-outlined"
                :text="$t('Decline')"
                @click="decline(notification)" />
            </div>
            <div v-if="notification.type === 'communication_chat_message'" class="flex flex-col w-full  gap-0.5">
               
					 <div class="flex items-center justify-between">
						<p class="block font-sans antialiased font-light leading-relaxed text-inherit">
                    <span class="font-semibold" >{{ notification?.data?.sender?.fullname }}</span> <span v-if="notification?.data?.message" >sends: {{ notification?.data?.message }}</span>
                </p>
						<div v-if="!notification.read_at" class="w-2.5 h-2.5 bg-[#00B8D9] rounded-full ml-auto"></div>
					 </div>
                <span class="text-xs text-disabled" >{{ formattedDate(notification?.created_at) }}</span>
                <div class="flex w-full flex-col  gap-0.5" v-if="notification?.data?.attachmentId">
                    <p class="block font-sans antialiased font-light leading-relaxed text-inherit">
                        Added a file to in <span class="font-bold" >File Manager</span>
                    </p>
                    <div class="flex items-center justify-between w-full p-2 mt-3 bg-white border rounded-md">
                        <div 
                           @click="previewFile(
                              route('document.show-in-chat', [notification?.data.groupId, notification?.data.attachmentId, notification?.document.type]),
                              notification?.document.type,
                              notification?.document.encid
                           )"
                        >
                            <Icon type="file" height="50" width="50" />
                        </div>
                        <div 
                           @click="previewFile(
                              route('document.show-in-chat', [notification?.data.groupId, notification?.data.attachmentId, notification?.document.type]),
                              notification?.document.type,
                              notification?.document.encid
                           )"
                        >
                            <h4>{{ notification?.data?.attachment_name }}</h4>
                        </div>
                        <div>
                        <!-- <GenericButton
                            :href="route('attachment.download', notification?.data?.attachmentId)"
                            type="button"
                            :isLink="true"
                            theme="inherit-outlined"
                            class="cursor-pointer hover:text-[#212B36] bg-white hover:border hover:border-[#919EAB52]"
                            :text="$t('Download')" /> -->
                           <a v-if="notification?.attachment?.id"
                              :href="route('attachment.download', notification?.attachment?.id)" target="_blank"
                              class="cursor-pointer hover:text-[#212B36] bg-white hover:border hover:border-[#919EAB52] items-center gap-2 rounded-lg px-3 py-1 font-bold leading-tight text-sm border border-secondary-10 text-secondary-8 bg-secondary-10"
                           >
                           {{ $t('Download') }}</a>
                        </div>
                    </div>
                </div>
                <div class="mt-3" v-if="notification?.data?.message || notification?.data?.attachmentId">
                    <GenericButton
                    @click="goToChat(notification?.data?.group_type, notification?.job.slug, notification?.data?.application_id, notification?.data?.message_id, notification?.data?.groupId)"
                    type="button"
                    theme="inherit-contained"
                    class="cursor-pointer hover:text-[#212B36] hover:bg-white hover:border hover:border-[#919EAB52] transition-all !py-1 !px-3"
                    :text="$t('Reply')" />
                </div>

            </div>
          </div>
   

        </div>
      </div>

      <div v-if="notifications.length === 0" class="py-5 text-center text-gray-500">
        {{ $t('You haven\'t notifications yet') }}
      </div>
      <OvnToast ref="toast"></OvnToast>
      <PreviewModal ref="previewModal"/>
    </div>
  </template>
<script>
import Avatar from '@/Components/Avatar.vue';
import GenericButton from '@/Components/GenericButton.vue';
import PreviewModal from "@/Components/PreviewContent/PreviewModal.vue";
import OvnToast from "@/Components/Toast.vue";
import Icon from '@/Icons/jobs-icons.vue';

import { Link, router, useForm } from '@inertiajs/vue3';
import axios from 'axios';
import moment from 'moment';

export default {
  data() {
    return {
      notifications: [],
      activeTab: 'all', // Default tab
      form: useForm({
        receiver_id: '',
        status: '',
        notification_id: '',
        friend_request_id: ''
      }),
    };
  },
  components: {
    Avatar,
    GenericButton,
    OvnToast,Icon,Link,PreviewModal
  },
  mounted() {
    this.fetchNotifications();
  },
  computed: {
    unreadNotifications() {
      return this.notifications.filter(notification => !notification.read_at);
    }
  },
  methods: {
    async fetchNotifications() {
      try {
        const response = await axios.get(route('notifications.index'));
        this.notifications = response.data;
        for (const i in this.notifications) {
          this.notifications[i].data = JSON.parse(this.notifications[i].data);
        }
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    },
    async markAllAsRead() {
      try {
        await axios.post(route('notifications.markAllAsRead'));
        this.fetchNotifications(); // Refresh the list after marking as read
      } catch (error) {
        console.error('Error marking notifications as read:', error);
      }
    },
    formattedDate(date) {
      return moment(date).format('DD MMM YYYY');
    },
    accepted(data) {
      this.form.receiver_id = data?.data?.sender?.id;
      this.form.status = 'accepted';
      this.form.notification_id = data?.id;
      this.form.friend_request_id = data?.notifiable?.id;

      this.form.post(route('friends.requests.send'), {
        onSuccess: page => {
          this.$refs.toast.show('Friend request accepted successfully!', 'success');
          this.fetchNotifications();
        },
      });
    },
    decline(data) {
      this.form.receiver_id = data?.data?.sender?.id;
      this.form.status = 'rejected';
      this.form.notification_id = data?.id;
      this.form.friend_request_id = data?.notifiable?.id;
      this.form.post(route('friends.requests.send'), {
        onSuccess: page => {
          this.$refs.toast.show('Friend request declined successfully!', 'success');
          this.fetchNotifications();
        },
      });
    },
    goToChat(groupType, jobSlug, applicationId, message_id, groupId){
        // console.log(jobSlug, applicationId)
        if(groupType == 'hr_collaboration'){

          router.get(route('communications.view', {
              job: jobSlug,
              application: applicationId
          }), {
              page: 'collaboration',
              message_id: message_id
          }, { preserveScroll: true });
          
        }else{
          router.get(route('chat.view',groupId))
        }
        
        this.$emit('handleToggle');
    },
   previewFile(src, type, attachmentId){
      this.$refs.previewModal.previewContent(src, type, attachmentId);
   }
  }
};
</script>
